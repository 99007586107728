<template>
  <div id="flightList" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">Flight Schedule</div>
        <v-btn class="theme-btn-even" @click="createFlight()" v-show="userdata.r_id == 4 || userdata.r_id == 21">
          <span class="I-create"></span>
          <span>Create Flight</span>
        </v-btn>
      </div>
    </div>
    <div id="calendarDATA" class="wrap-Main">
      <div class="box-S2">
        <CompListFlight ref="CompListFlight" :DataDateFromCalendar="datadatefromcalender" :Service="'flightselectcomplist'">
        </CompListFlight>
      </div>
      <div class="box-S2">
        <calendar :Service="'flightselectcomplist'" @DataCalender="BindingDataFromCalendar"></calendar>
      </div>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>

    <v-dialog v-model="dialogCreateFlight" persistent max-width="400">
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <div class="box-S4 flex-between-center">
            <div class="N-Page T-size-24 TF">Create Flight</div>
          </div>
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S4">
                <v-text-field label="Flight No." :readonly="readonly_eng ||
                  readonly_pilot ||
                  readonly_shore ||
                  readonly_finance
                  " v-model="flightno">
                </v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-select class="hideMessage" :items="itemsListCustomer" item-text="o_name" item-value="o_id"
                  label="Customer" v-model="customer" :readonly="readonly_eng ||
                    readonly_pilot ||
                    readonly_shore ||
                    readonly_finance
                    " return-object></v-select>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-select class="hideMessage" :items="itemsListFlightType" item-text="ft_name" item-value="ft_id"
                  label="Type Of Flight" v-model="flighttype" :readonly="readonly_eng ||
                    readonly_pilot ||
                    readonly_shore ||
                    readonly_finance
                    " return-object></v-select>
              </div>
            </div>
            <div class="b-row" v-show="flighttype.ft_id == 1">
              <div class="box-S4">
                <v-select class="hideMessage" :items="itemsListTerminal" item-text="tm_name" item-value="tm_id"
                  label="Terminal" v-model="terminal" :readonly="readonly_eng ||
                    readonly_pilot ||
                    readonly_shore ||
                    readonly_finance
                    " return-object></v-select>
              </div>
            </div>

            <div class="b-row" v-show="flighttype.ft_id == 1">
              <div class="box-S4">
                <v-select class="hideMessage" :items="itemsListCheckInVideo" item-text="v_name" item-value="v_id"
                  label="Brief Check in Video" v-model="videoCheckin" :readonly="readonly_eng ||
                    readonly_pilot ||
                    readonly_shore ||
                    readonly_finance
                    " return-object></v-select>
              </div>
            </div>

            <div class="b-row">
              <div class="box-S2">
                <v-menu :disabled="readonly_eng ||
                  readonly_pilot ||
                  readonly_shore ||
                  readonly_finance
                  " v-model="menuselect_depart_date" :close-on-content-click="false" transition="scale-transition"
                  offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field class="hideMessage input-date" v-model="depart_date" label="Departure Date" readonly
                      v-on="on" append-icon="mdi-calendar-range"></v-text-field>
                  </template>
                  <v-date-picker v-model="depart_date" :readonly="readonly_eng ||
                    readonly_pilot ||
                    readonly_shore ||
                    readonly_finance
                    " @input="menuselect_depart_date = false"></v-date-picker>
                </v-menu>
              </div>
              <div class="box-S2">
                <v-text-field label="Departure Time" :readonly="readonly_eng ||
                  readonly_pilot ||
                  readonly_shore ||
                  readonly_finance
                  " v-model="depart_time" type="time"></v-text-field>
              </div>
            </div>

            <div class="b-row">
              <div class="box-S4">
                <v-combobox class="hideMessage" :items="itemsListDeparture" v-model="selectDeparture" item-text="ap_name"
                  item-value="ap_id" label="Departure From" return-object @change="departureFromChange" :readonly="readonly_eng ||
                    readonly_pilot ||
                    readonly_shore ||
                    readonly_finance
                    "></v-combobox>
              </div>
            </div>

            <div class="box-btn">
              <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogCreateFlight = false">
                <span class="T-size-18">cancel</span>
              </v-btn>
              <v-btn :disabled="buttonSave" class="theme-btn-even btn-save Bsize100" text @click="GotoCreateFlight()">
                <span class="T-size-18">save</span>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import calendar from "../components/main/Calendar";
import CompListFlight from "../components/main/CompList";
import { format } from "date-fns";
import feathersClientUOA from "../plugins/feathers-client-uoa";
import { Data } from "@syncfusion/ej2-vue-grids";

export default {
  data: () => ({
    //Add
    flightno: "",

    today: new Date(),
    datadatefromcalender: new Date().toISOString().substr(0, 10),
    userdata: "",
    dialogCreateFlight: false,

    itemsListCustomer: [],
    customer: "",

    itemsListFlightType: [],
    flighttype: "",

    itemsListTerminal: [],
    terminal: "",

    itemsListCheckInVideo: [],
    videoCheckin: "",

    menuselect_depart_date: false,
    depart_date: format(new Date(), "yyyy-MM-dd"),
    depart_time: "06:00",

    readonly_flight: false,
    readonly_pilot: false,
    readonly_eng: false,
    readonly_shore: false,
    readonly_finance: false,

    buttonSave: false,

    itemsListDeparture: [],
    selectDeparture: "",
  }),
  components: {
    calendar,
    CompListFlight,
  },
  async mounted() {
    this.userdata = await JSON.parse(localStorage.getItem("user"));
    await this.enable_disable();
    await this.SelectListCustomer();
    await this.SelectListFlightType();
    await this.SelectListTerminal();
    await this.SelectListCheckInVideo();
    await this.renderListDeparture();
  },
  methods: {
    async enable_disable() {
      if (this.userdata.r_id == 4 || this.userdata.r_id == 21) {
        this.readonly_flight = true;
      } else if (this.userdata.r_id == 9) {
        this.readonly_eng = true;
      } else if (this.userdata.r_id == 12) {
        this.readonly_pilot = true;
      } else if (this.userdata.r_id == 2) {
        this.readonly_shore = true;
      } else if (this.userdata.r_id == 14) {
        this.readonly_finance = true;
      }
    },
    Back() {
      this.$router.go(-1);
    },
    async BindingDataFromCalendar(dataDate) {
      this.datadatefromcalender = dataDate;
      await this.$refs.CompListFlight.renderListFlight(dataDate);
    },

    async SelectListCustomer() {
      try {
        var res = await feathersClientUOA.service("customer").find({});
        this.itemsListCustomer = res.data;
        this.customer = this.itemsListCustomer[0];
      } catch (error) {
        console.log(error);
        alert("Flight 1 ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },

    async SelectListFlightType() {
      try {
        var q = {};
        var res = await feathersClientUOA.service("flighttype").find({});
        this.itemsListFlightType = res.data;
        this.flighttype = this.itemsListFlightType[0];
      } catch (error) {
        console.log(error);
        alert("Flight 2 ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },

    async SelectListTerminal() {
      try {
        var q = {};
        q.$sort = {
          tm_id: 1,
        };
        q.retire = 0;
        var res = await feathersClientUOA
          .service("terminal")
          .find({ query: q });
        this.itemsListTerminal = res.data;
        //Add
        this.terminal = this.itemsListTerminal[0];
      } catch (error) {
        console.log(error);
        alert("Flight 3 ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },

    async SelectListCheckInVideo() {
      try {
        var q = {};
        q.$sort = {
          v_id: 1,
        };
        var res = await feathersClientUOA
          .service("filevideo")
          .find({ query: q });
        this.itemsListCheckInVideo = res.data;
        this.videoCheckin = this.itemsListCheckInVideo[0];
      } catch (error) {
        console.log(error);
        alert("Flight 4 ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },

    async GotoCreateFlight() {
      this.buttonSave = true;
      //Get FH_Basic
      var q = {};
      q.o_id = this.customer.o_id;
      var res = await feathersClientUOA.service("customer").find({ query: q });

      let FH_Basic = null;
      let video = null;

      if (this.flighttype.ft_id == 1) {
        if (this.customer.o_id == 4 && this.selectDeparture.ap_id == 2) {
          FH_Basic = "Airtime";
        } else {
          FH_Basic = res.data[0].FH_basis;
        }
        video = this.videoCheckin.v_id;
      } else {
        FH_Basic = null;
        video = null;
      }

      try {
        if (
          this.flightno != "" &&
          this.depart_date != "" &&
          this.depart_time != ""
        ) {
          var DataAdd = {
            f_flight_no: this.flightno,
            o_id: this.customer.o_id,

            ft_id: this.flighttype.ft_id,
            f_type_of_flight: this.flighttype.ft_name,

            tm_id: this.terminal.tm_id,
            v_id: video,

            ap_depart_date: this.depart_date + " " + this.depart_time,
            ap_dest_date: this.depart_date + " " + this.depart_time,

            fs_id: 1,
            ap_departairport_id: this.selectDeparture.ap_id,
            ap_destairport_id: this.selectDeparture.ap_id,

            cb: this.userdata.FullName,
            cd: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),

            //Add 20220324
            // FH_basis: "Airtime",
            //Edit 20220419
            FH_basis: FH_Basic,
          };
        }
        var res = await feathersClientUOA.service("flight").create(DataAdd);

        //Create Delay Add 20220328
        var dataDelay = {
          f_id: res.f_id,
          d_id: 0,
          dc_id: null,
          fd_time: 0,
          fd_remark: "",
        };
        await feathersClientUOA.service("flightdelay").create(dataDelay);
        //Create Delay Add 20220328
        //SEQ
        let properties = {
          f_id: res.f_id,
          f_flight_no: DataAdd.f_flight_no,
          o_id: DataAdd.o_id,
          ft_id: DataAdd.ft_id,
          ft_name: DataAdd.f_type_of_flight,
          tm_id: DataAdd.tm_id,
          v_id: DataAdd.v_id,
          fs_id: DataAdd.fs_id,
          FH_basis: DataAdd.FH_basis,
          ap_departairport_id: DataAdd.ap_departairport_id,
          ap_departairport_name: this.selectDeparture.ap_name,
          ap_depart_date: DataAdd.ap_depart_date,
          ap_destairport_id: DataAdd.ap_destairport_id,
          ap_destairport_name: this.selectDeparture.ap_name,
          ap_dest_date: DataAdd.ap_dest_date
        };
        this.$log.Info('{action} by {username} ({role}) : f_id={f_id}', { action: "CreateFlight", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
        this.$Notification.FlightCreate(res.f_id);
        var url = "#/flightdetail/Edit/" + res.f_id;
        window.location.replace(url);
      } catch (error) {
        //SEQ LOG
        this.$log.Error("CreateFlight, Error : {error}", { error });
        console.log("CreateFlight, Error : {error}" + error);
      }
    },

    async renderListDeparture() {
      try {
        this.itemsListDeparture = [];
        var data = await feathersClientUOA.service("mastercommand").find({});
        var airports = data[0].airport.filter((x) => x.ap_checkin == true);
        airports.forEach((element) => {
          this.itemsListDeparture.push(element);
        });
      } catch (error) { }
      this.selectDepartureFrom();
    },

    async selectDepartureFrom() {
      try {
        let departureChange =
          await this.$LocalStorageService.getDepartureChange();
        if (departureChange == null) {
          this.selectDeparture = {
            ap_id: this.itemsListDeparture[0].ap_id,
            ap_name: this.itemsListDeparture[0].ap_name,
          };
        } else {
          let obj = JSON.parse(departureChange);
          this.selectDeparture = { ap_id: obj.ap_id, ap_name: obj.ap_name };
        }
      } catch (error) {
        console.log(
          "SelectListCustomer ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
          error
        );
      }
    },

    departureFromChange() {
      this.$LocalStorageService.setDepartureChange(
        this.selectDeparture.ap_id,
        this.selectDeparture.ap_name
      );
    },

    async createFlight() {
      await this.renderListDeparture();
      this.dialogCreateFlight = true;
    },
  },
};
</script>

<style></style>